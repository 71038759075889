<template>
	<div id="login-background">
		<div id="login-wrapper">
			<div id="login-banner">
				<transition v-if="animationenter">
					<div id="login-box" class="fade-enter-in-active">
						<h2 id="login-box-title" class="login-text">登录</h2>
						<p class="textleft login-text" id="welcomeText">
							Welcome to THU Comptition System
						</p>
						<el-input
							id="inputUserName"
							class="register-input"
							placeholder="帐号"
							v-model="username"
							style
						></el-input>
						<el-input
							id="inputPassWord"
							class="register-input"
							placeholder="密码"
							v-model="password"
							show-password
						></el-input>
						<el-tooltip class="item" effect="dark" placement="top">
							<template slot="content">
								管理员邮箱：{{adminEmail}}
							</template>
						<el-link target="tempIframe" style="color: #99cc99" class="register textleft" :href="'mailto:' + adminEmail"
							>忘记密码？联系管理员</el-link
						>
						</el-tooltip>
						<el-button
							type="primary"
							style="width: 100%"
							@click="login(username, password)"
							>登录</el-button
						>
						<footer>
							<p id="login-info">@<a href="/about">掌上马杯 2020</a></p>
						</footer>
						<iframe name="tempIframe" style="display:none;"></iframe>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<style scoped>
/* styles */
#login-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("../assets/background.jpg") no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	box-shadow: 3px;
}
#login-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(30, 30, 30, 0.3);
}
#login-box {
	size: auto;
	background-color: rgba(153, 102, 204, 0.8);
	border: 2px solid rgb(153, 102, 204);
	border-radius: 8px;
	padding: 0 3% 0 3%;
	position: relative;
	width: 25%;
	margin-left: auto;
	margin-right: 10%;
	max-width: 400px;
	min-width: 300px;
}
#login-banner {
	margin-top: 15%;
	background-color: rgba(153, 102, 204, 0.4);
}
.register-input {
	margin-bottom: 10px;
}
.login-text {
	color: #ffffcc;
}
#login-info {
	font-size: 10px;
	color: rgb(200, 200, 200);
}
/* animations */
.fade-enter-in-active {
	transition: opacity 0.5s;
}
#login-banner a {
	color: rgb(200, 200, 200);
}
.el-link {
	margin-bottom: 8px;
	font-size: 13px;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "Login",
	data() {
		return {
			MIN_USERNAME_LENGTH: 2,
			MAX_USERNAME_LENGTH: 50,
			MIN_PASSWORD_LENGTH: 6,
			MAX_PASSWORD_LENGTH: 30,
			animationenter: true,
			password: "",
			username: "",
			adminEmail: "tybqtz@mail.tsinghua.edu.cn",
		};
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},

		/*
		 * Log in and store the jwt/token in localstorage
		 * Params: Username, Password
		 * Returns: None
		 * TODO: Use vuex to store jwt/token
		 */
		login(usr, pwd) {
			if (usr.length == 0) {
				this.messageBox("请输入用户名", "error");
				return;
			} else if (pwd.length == 0) {
				this.messageBox("请输入密码", "error");
				return;
			} else if (
				usr.length < this.MIN_USERNAME_LENGTH ||
				usr.length > this.MAX_USERNAME_LENGTH
			) {
				this.messageBox("请输入有效的用户名", "error");
				return;
			} else if (
				pwd.length < this.MIN_PASSWORD_LENGTH ||
				pwd.login > this.MAX_PASSWORD_LENGTH
			) {
				this.messageBox("请输入有效的密码", "error");
				return;
			}
			axios({
				url: "/api/login",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
				data: qs.stringify({ username: usr, password: pwd }),
			})
				.then((res) => {
					// console.log(res);
					this.messageBox("欢迎您，" + this.username, "success");
					localStorage.setItem("token", res.data.jwt);
					this.$router.push("/" + String(res.data.usertype));
				})
				.catch((err) => {
					this.messageBox(
						"登录失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					this.password = "";
					console.log("Log in Failed");
					console.log(err.response);
				});
		},
	},
	computed: {},
};
</script>